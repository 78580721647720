<!--
 * @Author: 肖槿
 * @Date: 2021-10-21 15:13:59
 * @Description: 精选联盟
 * @LastEditors: 肖槿
 * @LastEditTime: 2021-10-26 18:16:04
 * @FilePath: \s2b-admin\src\pages\admin\goods\featured\featured.vue
-->
<template>
  <div class="goods-featured">
    <a-button type="primary" style="margin-right: 20px" @click="fetchGoodsDrawVisibled = true">抓取商品</a-button>
<!--    <a-button type="primary" @click="handleExport">商品导出</a-button>-->
    <div class="goods-featured-table">
      <a-table
        :scroll="{ x: 1200, y: tableScrollY }"
        :rowKey="(record) => record.id"
        :columns="columns"
        @change="pageChange"
        :dataSource="tableData"
        :pagination="paginationStruct"
        :loading="loading"
        :locale="{ filterConfirm: '暂无数据' }"
      >
        <template slot="operation" slot-scope="row">
          <a-row type="flex" justify="center" :gutter="[16, 16]">
            <a-col :span="8">
              <a-button size="small" @click="showDetailInvoid(row.uid)">查看</a-button>
            </a-col>
            <a-col :span="8">
              <a-popconfirm
                title="是否删除该批次"
                ok-text="是"
                cancel-text="否"
                @confirm="deleteSpired(row.uid)"
              >
                <a-button size="small">删除</a-button>
              </a-popconfirm>
            </a-col>
          </a-row>
        </template>
        <template slot="spiderInfo" slot-scope="row">
          <a-row class="spider-condition" type="flex" :gutter="[16, 16]">
            <a-col class="a-col" v-if="row.goods_id">
              <span>商品ID：</span>
              <span class="condition">{{ row.goods_id + '' }}</span>
            </a-col>
            <a-col class="a-col" v-if="row.goods_first_cid_name">
              <span>商品类目：</span>
              <span class="condition">{{ row.goods_first_cid_name }}</span>
            </a-col>
            <a-col class="a-col"  v-if="row.goods_title">
              <span>关键词：</span>
              <span class="condition">{{ row.goods_title }}</span>
            </a-col>
            <a-col class="a-col" v-if="row.goods_price_min || row.goods_price_max">
              <span>价格区间：</span>
              <span class="condition">{{ row.goods_price_min || 0 }}~{{ row.goods_price_max }}</span>
            </a-col>
            <a-col class="a-col" v-if="row.goods_sell_num_min || row.goods_sell_num_max">
              <span>历史销量：</span>
              <span class="condition">{{ row.goods_sell_num_min || 0 }}~{{ row.goods_sell_num_max }}</span>
            </a-col>
            <a-col class="a-col" v-if="row.goods_cos_fee_min || row.goods_cos_fee_max">
              <span>佣金金额：</span>
              <span class="condition">{{ row.goods_cos_fee_min || 0 }}~{{ row.goods_cos_fee_max }}</span>
            </a-col>
            <a-col class="a-col" v-if="row.goods_cos_ratio_min || row.goods_cos_ratio_max">
              <span>佣金率：</span>
              <span class="condition">{{ row.goods_cos_ratio_min || 0 }}%~{{ row.goods_cos_ratio_max }}%</span>
            </a-col>
            <a-col class="a-col" v-if="row.goods_search_type !== undefined">
              <span>排序：</span>
              <span class="condition">{{ searchTypeName(row.goods_search_type) }}({{row.goods_sort_type == 0 ? '升序' : '倒序'}})</span>
            </a-col>
          </a-row>
        </template>
      </a-table>
    </div>
    <a-drawer
      title="抓取商品"
      placement="right"
      width="650"
      @close="fetchGoodsCloseInvoid"
      :visible="fetchGoodsDrawVisibled"
    >
      <div class="featured-fetch-tip">
        <span>*</span>
        请填写抓取条件，条件尽量细化避免抓取时间过长
      </div>
      <a-form-model
        :model="spiderForm"
        ref="spiderRef"
        :rules="spiderRules"
        :label-col="{ span: 5 }"
        :wrapper-col="{ span: 12 }"
        class="featured-condition-form"
      >
        <a-form-model-item label="商品ID" prop="goods_id">
          <a-input placeholder="请输入商品ID" v-model="spiderForm.goods_id" />
        </a-form-model-item>
        <a-form-model-item label="关键词" prop="goods_title">
          <a-input placeholder="请输入商品关键词" v-model="spiderForm.goods_title" />
        </a-form-model-item>
        <a-form-model-item label="商品类目" prop="goods_first_cid">
          <a-select
            v-model="spiderForm.goods_first_cid"
            placeholder="请选择商品类目"
            show-search
            :filter-option="filterCategoryOption"
          >
            <a-select-option
              v-for="item in categoryList"
              :key="item.id + '_category'"
              :value="item.id"
            >{{ item.name }}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="价格区间">
          <a-form-model-item
            :style="{ display: 'inline-block', width: 'calc(50% - 12px)' }"
            prop="goods_price_min"
          >
            <a-input-number
              style="width: 100%"
              :min="0"
              placeholder="请输入商品最低价格"
              v-model="spiderForm.goods_price_min"
            />
          </a-form-model-item>
          <span :style="{ display: 'inline-block', width: '24px', textAlign: 'center' }">-</span>
          <a-form-model-item
            :style="{ display: 'inline-block', width: 'calc(50% - 12px)' }"
            prop="goods_price_max"
          >
            <a-input-number
              style="width: 100%"
              :min="0"
              placeholder="请输入商品最高价格"
              v-model="spiderForm.goods_price_max"
            />
          </a-form-model-item>
        </a-form-model-item>
        <a-form-model-item label="历史销量">
          <a-form-model-item
            :style="{ display: 'inline-block', width: 'calc(50% - 12px)' }"
            prop="goods_sell_num_min"
          >
            <a-input-number
              style="width: 100%"
              :min="0"
              placeholder="请输入商品最低历史销量"
              v-model="spiderForm.goods_sell_num_min"
            />
          </a-form-model-item>
          <span :style="{ display: 'inline-block', width: '24px', textAlign: 'center' }">-</span>
          <a-form-model-item
            :style="{ display: 'inline-block', width: 'calc(50% - 12px)' }"
            prop="goods_sell_num_max"
          >
            <a-input-number
              style="width: 100%"
              :min="0"
              placeholder="请输入商品最高历史销量"
              v-model="spiderForm.goods_sell_num_max"
            />
          </a-form-model-item>
        </a-form-model-item>
        <a-form-model-item label="佣金金额区间">
          <a-form-model-item
            :style="{ display: 'inline-block', width: 'calc(50% - 12px)' }"
            prop="goods_cos_fee_min"
          >
            <a-input-number
              style="width: 100%"
              :min="0"
              placeholder="请输入商品最低佣金金额"
              v-model="spiderForm.goods_cos_fee_min"
            />
          </a-form-model-item>
          <span :style="{ display: 'inline-block', width: '24px', textAlign: 'center' }">-</span>
          <a-form-model-item
            :style="{ display: 'inline-block', width: 'calc(50% - 12px)' }"
            prop="goods_cos_fee_max"
          >
            <a-input-number
              style="width: 100%"
              :min="0"
              placeholder="请输入商品最高佣金金额"
              v-model="spiderForm.goods_cos_fee_max"
            />
          </a-form-model-item>
        </a-form-model-item>
        <a-form-model-item label="佣金率区间">
          <a-form-model-item
            :style="{ display: 'inline-block', width: 'calc(50% - 12px)' }"
            prop="goods_cos_ratio_min"
          >
            <a-input-number
              style="width: 100%"
              :min="0"
              :max="100"
              placeholder="请输入商品最低佣金率"
              v-model="spiderForm.goods_cos_ratio_min"
            />
          </a-form-model-item>
          <span :style="{ display: 'inline-block', width: '24px', textAlign: 'center' }">-</span>
          <a-form-model-item
            :style="{ display: 'inline-block', width: 'calc(50% - 12px)' }"
            prop="goods_cos_ratio_max"
          >
            <a-input-number
              style="width: 100%"
              :min="0"
              :max="100"
              placeholder="请输入商品最高佣金率"
              v-model="spiderForm.goods_cos_ratio_max"
            />
          </a-form-model-item>
        </a-form-model-item>
        <a-form-model-item label="抓取数量" prop="spider_count">
          <a-input-number :min="1" placeholder="请输入商品抓取数量" v-model="spiderForm.spider_count" />
        </a-form-model-item>
        <a-form-model-item label="排序">
          <a-form-model-item
            :style="{ display: 'inline-block', width: 'calc(70% - 12px)' }"
            prop="goods_search_type"
          >
            <a-select v-model="spiderForm.goods_search_type">
              <a-select-option
                v-for="item in searchType"
                :key="item.value + '_keys'"
                :value="item.value"
              >{{ item.label }}</a-select-option>
            </a-select>
          </a-form-model-item>
          <span :style="{ display: 'inline-block', width: '24px', textAlign: 'center' }">-</span>
          <a-form-model-item
            :style="{ display: 'inline-block', width: 'calc(30% - 12px)' }"
            prop="goods_sort_type"
          >
            <a-select v-model="spiderForm.goods_sort_type">
              <a-select-option value="0">升序</a-select-option>
              <a-select-option value="1">降序</a-select-option>
            </a-select>
          </a-form-model-item>
        </a-form-model-item>

        <a-form-model-item :wrapper-col="{ span: 12, offset: 5 }">
          <a-button @click="fetchGoodsCloseInvoid">取消</a-button>
          <a-button type="primary" style="margin-left: 20px" @click="handleSubmit">开始抓取</a-button>
        </a-form-model-item>
      </a-form-model>
    </a-drawer>
  </div>
</template>

<script>
import { defineComponent, ref, onMounted } from '@vue/composition-api';
import { getGoodsSpider, postGoodsSpider, getNewGoodsCategorys, deleteGoodsSpider,exportKrGoodsList } from '@/service/goods';

const columns = [
  {
    title: '批次',
    fixed: 'left',
    dataIndex: 'uid',
    width: 200,
  },
  {
    title: '抓取时间',
    dataIndex: 'create_time',
    width: 200,
  },
  {
    title: '商品数量',
    width: 150,
    dataIndex: 'spider_count',
  },
  {
    title: '抓取条件',
    width: 1000,
    scopedSlots: { customRender: 'spiderInfo' },
  },
  {
    title: '操作',
    align: 'center',
    fixed: "right",
    width: 180,
    scopedSlots: { customRender: 'operation' },
  },
];
const searchType = [
  {
    label: '默认排序',
    value: '0'
  },
  {
    label: '按销量排序',
    value: '1'
  },
  {
    label: '按佣金金额排序',
    value: '3'
  },
  {
    label: '按佣金率排序',
    value: '4'
  },
  {
    label: '按价格排序',
    value: '2'
  }
]
export default defineComponent({
  name: 'featured',
  setup(prop, ctx) {
    const windowHeight = document.documentElement.clientHeight || 1000;
    const tableScrollY =ref(windowHeight - 285);
    const paginationStruct = ref({
      current: 1,
      pageSize: 10,
      total: 0
    })
    const searchTypeName = (val) => {
      const item = searchType.find(v => v.value === val + '')
      return item.label
    }
    const spiderForm = ref({
      goods_id: undefined,
      goods_title: undefined,
      goods_first_cid: undefined,
      goods_price_min: undefined,
      goods_price_max: undefined,
      goods_sell_num_min: undefined,
      goods_sell_num_max: undefined,
      goods_cos_fee_min: undefined,
      goods_cos_fee_max: undefined,
      goods_cos_ratio_min: undefined,
      goods_cos_ratio_max: undefined,
      spider_count: 1,
      goods_search_type: '0',
      goods_sort_type: '1',
    })
    const deleteSpired = async (uid) => {
      const { res } = await deleteGoodsSpider(uid)
      if (res.success) {
        ctx.root.$message.success('删除成功')
        invokeGetGoodsSpiderFun()
      }
    }
    const spiderRules = ref({
      spider_count: [{ required: true, message: '请输入抓取数量', trigger: 'blur' },]
    })
    const showDetailInvoid = (id) => {
      // this.$router.push({ path: '/admin/operate/head_activity/goods', query: { id: row.id } });
      var path='/admin/goods/featured/' + id;
      ctx.root.$router.push({
        path:path
      });

    }
    // const categoryName = (val) => {
    //   const items = categoryList.value.find(v => v.id === val)
    //   return items ? items.name : ''
    // }
    const pageChange = ({ current }) => {
      paginationStruct.value.current = current
      invokeGetGoodsSpiderFun()
    }
    const categoryList = ref([])
    const loading = ref(false)
    let tableData = ref([])
    const invokeGetGoodsSpiderFun = async () => {
      const { current, pageSize } = paginationStruct.value
      loading.value = true
      const { res: { data } } = await getGoodsSpider({
        page: current + '',
        page_size: pageSize + ''
      })
      loading.value = false
      if (data.results) {
        tableData.value = data.results
        paginationStruct.value.total = data.count
      }
    }
    const resetTable = () => {
      paginationStruct.value.current = 1
      invokeGetGoodsSpiderFun()
    }
    const fetchGoodsCloseInvoid = () => {
      fetchGoodsDrawVisibled.value = false
      ctx.refs.spiderRef.resetFields()
    }
    const fetchGoodsDrawVisibled = ref(false)
    const invokeGetGoodsCategory = async (params) => {
      const { res: { data } } = await getNewGoodsCategorys(params)
      categoryList.value = data
    }
    const handleSubmit = (e) => {
      e.preventDefault();
      ctx.refs.spiderRef.validate(async (validate) => {
        if (!validate) return
        const obj = {}
        Object.keys(spiderForm.value).forEach(v => {
          if (spiderForm.value[v] !== null) {
            obj[v] = spiderForm.value[v] || undefined
          }
        })
        const { res: { success } } = await postGoodsSpider(obj)
        if (success) {
          fetchGoodsCloseInvoid()
          resetTable()
        }
      })
    }
    const filterCategoryOption = (input, option) => {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    }
    onMounted(() => {
      invokeGetGoodsSpiderFun()
      invokeGetGoodsCategory({ level: 1 })
    })
    return {
      tableScrollY,
      invokeGetGoodsSpiderFun,
      invokeGetGoodsCategory,
      showDetailInvoid,
      tableData,
      searchTypeName,
      spiderRules,
      spiderForm,
      filterCategoryOption,
      categoryList,
      resetTable,
      handleSubmit,
      searchType,
      pageChange,
      loading,
      paginationStruct,
      columns,
      fetchGoodsDrawVisibled,
      deleteSpired,
      fetchGoodsCloseInvoid
    }
  },
  methods: {
    // 导出
    async handleExport() {
      const obj = {}
      Object.keys(this.spiderForm.value).forEach(v => {
        if (this.spiderForm.value[v] !== null) {
          obj[v] = this.spiderForm.value[v]
        }
      })
      this.listLoading = true;
      const { res } = await exportKrGoodsList(obj);
      this.listLoading = false;
      if (res.success) {
        this.$message.success('导出成功');
        location.href = res.data.detail_url;
      }
    },
  }
});
</script>

<style lang="less" scoped>
.goods-featured {

  .goods-featured-table {
    margin-top: 10px;
  }
  & .spider-condition{
    color: #999999;
    & .condition {
      color: #333333;
    }
  }
}
</style>
<style lang="less">
  .a-col {
    margin-right: 30px;
  }
.featured-fetch-tip {
  color: #e83741;
  margin-bottom: 10px;
}
</style>
